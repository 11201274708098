<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 19px !important;

  .v-application,
  pre {
    font-family: "Rajdhani", sans-serif !important;
    line-height: 1.2;
    white-space: break-spaces;
  }

  .v-btn {
    font-weight: bold;
  }
  table td .theme--light.v-input textarea {
    font-weight: bold;
  }
}
h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}
pre {
  white-space: pre-wrap;
}
.text-start pre {
  padding-bottom: 8px;
}
div.v-text-field__slot textarea {
  line-height: 1.4em;
  // min-height: 15px;
  // height: 15px;
  padding-top: 5px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.text-start {
  padding-top: 10px;
}
.text-start {
  vertical-align: top;
}
@media print {
  pre {
    display: table-cell !important;
    margin: 2px 0 0 0 !important;
    padding: 3px 0 !important;
    white-space: pre-line !important;
  }
  .text-start {
    width: 12.5% !important;
    vertical-align: top !important;
  }
  .text-start > div:nth-child(1) {
    display: flex !important;
    flex-direction: column !important;
  }
  .noPrint {
    display: none !important;
  }
  .printWide {
    width: 100% !important;
  }
  h3,
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1 !important;
  }
  .col {
    padding: 4px !important;
  }
  main,
  .container,
  .row,
  .pr-3,
  .v-card__subtitle,
  .v-card__text,
  .v-card__title,
  .v-sheet.v-card {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none !important;
  }
  .v-input--selection-controls,
  .v-input__slot {
    margin: 2px 0 0 0 !important;
    padding: 0 !important;
    display: table-cell !important;
  }
  .fa-square {
    display: none !important;
  }
  label {
    display: none !important;
  }
  .v-input__control {
    flex-direction: inherit !important;
    flex-wrap: inherit !important;
    display: block;
  }
  textarea {
    min-height: 0px !important;
    //flex-direction: inherit !important;
    font-size: 0.6rem !important;
    color: teal !important;
    resize: none !important;
  }
  .v-sheet.v-card:not(.v-sheet--outlined),
  .v-application .elevation-2 {
    box-shadow: 0px 0px 0px 0px !important;
    -webkit-box-shadow: 0px 0px 0px 0px !important;
  }
  td,
  .v-input,
  .v-text-field input,
  .v-text-field__prefix {
    color: black !important;
    padding: 0px 3px !important;
    //height: 20px !important;
    font-size: 0.6rem !important;
    min-height: 20px !important;
  }
  th {
    color: black !important;
    font-size: 0.7rem !important;
    background-color: lightgray !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
    //height: 10px !important;
  }
}
@media screen {
  .onlyPrint {
    display: none !important;
  }
}
</style>
