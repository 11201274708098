import { render, staticRenderFns } from "./FooterBar.vue?vue&type=template&id=59e74d7a&scoped=true&"
var script = {}
import style0 from "./FooterBar.vue?vue&type=style&index=0&id=59e74d7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e74d7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VFooter})
