var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-app-bar',{staticClass:"noPrint",attrs:{"color":"white","light":"","app":"","clipped-left":"","flex":""}},[(!_vm.draw)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.draw = !_vm.draw}}}):_vm._e(),_c('v-spacer',{staticClass:"logospacer"}),_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink ml-5 mt-5",attrs:{"alt":"timetracker Logo","src":require("../assets/timetracker.png"),"contain":"","transition":"scale-transition","width":"180"}})],1),_c('v-spacer',{staticClass:"loginname"}),(_vm.currentUser)?_c('div',{staticClass:"d-flex align-right ml-4 loginname",attrs:{"title":'User: ' +
        _vm.currentUser.name +
        '\nMail: ' +
        _vm.currentUser.email +
        '\nRole: ' +
        _vm.currentUser.role}},[_c('i',{staticClass:"fa fa-user-circle mr-2",attrs:{"aria-hidden":"true"}})]):_vm._e()],1),_c('v-navigation-drawer',{staticClass:"noPrint",attrs:{"app":"","clipped":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('footer-bar')]},proxy:true}]),model:{value:(_vm.draw),callback:function ($$v) {_vm.draw=$$v},expression:"draw"}},[(_vm.currentUser)?_c('v-list',{staticClass:"grey mt-3 lighten-4",attrs:{"dense":""}},[_vm._l((_vm.items),function(item,i){return [(item.heading)?_c('v-row',{key:i,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(item.heading)?_c('v-subheader',[_vm._v(_vm._s(item.heading))]):_vm._e()],1)],1):(item.divider)?_c('v-divider',{key:i,staticClass:"my-4",attrs:{"dark":""}}):_c('v-list-item',{key:i,class:_vm.$route.path === '/' + item.action ||
            _vm.$route.path.includes(item.route)
              ? 'highlighted'
              : '',attrs:{"active-class":"highlighted","link":""},on:{"click":function($event){return _vm.invokeNavElement(item.action)}}},[_c('v-list-item-action',[_c('i',{class:item.icon})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.text))])],1)],1)]})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }